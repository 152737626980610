<template>
  <div></div>
</template>

<script>

import axios from "axios";
import {api} from "../route";
import Vue from "vue";

export default Vue.extend({
  async beforeRouteEnter(to, from, next) {
    const [contest] = await Promise.all([
      (await axios.get(api('/contest/current'))).data,
    ])
    console.log(contest)
    if (contest.rounds)
      return next('/vbr3/submit')
    next('/vbr3/signup')
  }
})

</script>